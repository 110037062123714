import React from "react"
import { Link } from "react-router-dom"
import BackendClient from "../lib/BackendClient"

import { DataGrid, GridColDef } from '@mui/x-data-grid'

const columns: GridColDef[] = [

    { field: 'group', headerName: 'Folder', width: 200, valueGetter: (params) => {
        let g = params.row.name.split('/').slice(-2)[0]
        g = g.toUpperCase()
        return g
    }},

    {
        field: 'name',
        headerName: 'Dialog',
        width: 300,
        valueGetter: (params) => {
            return params.row.name.split('/').slice(-1)[0]
        },
        renderCell: (params) => {
            return <Link to={`/content/${params.row.name}`}>{params?.value}</Link>
        },
    },
    { field: 'chapters', headerName: 'Chapters', width: 100 },
    { field: 'title', headerName: 'Title', width: 500 },
]

const toTitleCase = (str: string): string => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    )
}

const Content = () => {
    const [dialogs, setDialogs] = React.useState<string[]>([])
    const [groups, setGroups] = React.useState<string[]>([])
    const [selectedGroup, setSelectedGroup] = React.useState<string>('')

    React.useEffect(() => {
        const fetch = async () => {
            const result = await BackendClient.instance.dialogs()
            const resultGroups: string[] = []
            for(const dialog of result) {
                const group = dialog.name.split('/').slice(-2)[0]
                if(!resultGroups.includes(group)) {
                    resultGroups.push(group)
                }
            }
            setGroups(resultGroups)
            setDialogs(result)
        }

        if(dialogs.length === 0) fetch()

    })

    return (
        <>
            <h1>Content</h1>
            <div style={{flexDirection: 'row', flex:1}}>
            {groups.map((group, index) => {
                return (
                    <div key={`${group}-${index}`}>
                        <Link to={``} onClick={() => setSelectedGroup(group)}>{toTitleCase(group)}</Link>
                    </div>
                    )
            })
            }
            </div>

            { selectedGroup &&
            <DataGrid
                rows={dialogs.filter((d:any) => d.name.startsWith(selectedGroup + '/'))}
                columns={columns}
                getRowId={(row) => row.name}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                  }}
            />
            }
        </>
    )
}

export default Content
