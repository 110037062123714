import React from "react"
import BackendClient from "../lib/BackendClient"

const Settings = () => {
    const [config, setConfig] = React.useState<any>({})

    React.useEffect(() => {
        const fetch = async () => {
            const result = await BackendClient.instance.config()
            console.log('result:', result)
            setConfig(result)
        }

        if(Object.keys(config).length === 0) fetch()
    })



    return (
        <>
            <h1>Settings</h1>
            <pre>{JSON.stringify(config)}</pre>

        </>
    )
}

export default Settings
