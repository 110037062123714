import React from "react"
import BackendClient, { IAnswer } from "../lib/BackendClient"
import { useParams, Link } from "react-router-dom"
import ChatSimulator from "../components/common/ChatSimulator"
import '../App.css'

const defaultParams = {
    text: 'hallo',
    initial: 'welt',
}

const defaultQuest = {
    title: 'Angst vor Spinnen',
    thoughts: [{thought: 'Spinnen sind eklig'}],
    visions: [{thought: 'Ich habe den Mut, eine Spinne in die Hand zu nehmen'}]
}


export const Dialog = () => {
    const routeParams = useParams()
    const requestedDialog = `${routeParams.directory}/${routeParams.file}`
    const [loaded, setLoaded] = React.useState<boolean>(false)
    const [dialog, setDialog] = React.useState<string>('')
    const [history, setHistory] = React.useState<IAnswer[]>([])
    const [params, setParams] = React.useState(defaultParams)
    const [quest, setQuest] = React.useState<any|undefined>(defaultQuest)
    const [showParams, setShowParams] = React.useState<boolean>(false)
    const [chapters, setChapters] = React.useState<number>(1)
    const [currentChapter, setCurrentChapter] = React.useState<number>(parseInt(routeParams.chapter as string) || 0)
    const [liveParams, setLiveParams] = React.useState<any>({})

    const textRef = React.useRef<null | HTMLDivElement>(null)
    const [cursorPosition, setCursorPosition] = React.useState<Range | null>(null);

    const fetch = async () => {
        const result = await BackendClient.instance.getDialog(requestedDialog, currentChapter)
        //console.log('initial fetch', result)
        console.log('initial fetch')
        setDialog(result)
        formatText(result)

        // get number of chapters
        const allDialogs = await BackendClient.instance.dialogs()
        setChapters(allDialogs.filter((d: any) => d.name.startsWith(requestedDialog))[0].chapters)
    }

    if(!loaded) {
        fetch()
        setLoaded(true)
    }

    //React.useEffect(() => { formatText(dialog) }, [dialog])

    const restart = async () => {
        const result = await BackendClient.instance.simulateDialog(dialog, params, quest)
        console.log(result)
        setHistory([result.answer])
        setLiveParams(result.params)
    }

    const saveCursorPosition = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
          setCursorPosition(selection.getRangeAt(0).cloneRange())
          console.log('saveCursorPosition', selection.getRangeAt(0).cloneRange())
        }
      }

      const restoreCursorPosition = () => {
        if (cursorPosition) {
          const selection = window.getSelection();
          if (selection) {
            selection.removeAllRanges();
            selection.addRange(cursorPosition);
          }
        }
      };


    const formatText = (t: string) => {
        saveCursorPosition()
        if(!textRef.current) return
        let formattedText = t
        //formattedText = formattedText.replace(/^(#!.*)/mg, '<h3>$1</h3>') || ''
        formattedText = formattedText.replace(/^(#.*)/mg, '<b>$1</b>') || ''
        formattedText = formattedText.replace(/^(-.*)/mg, '<small>$1</small>')
        formattedText = formattedText.replace(/^(\{.*\})/mg, '<small>$1</small>')
        formattedText = formattedText.replace(/^(GOTO.*)/mg, '<b>$1</b>')
        formattedText = formattedText.replace(/^(IF.*)/mg, '<small><b>$1</b></small>')
        formattedText = formattedText.replace(/^(:.*)/mg, '<small><b>$1</b></small>')
        formattedText = formattedText.replace(/^(>.*)/mg, '<small><b>$1</b></small>')
        formattedText = formattedText.replace(/^(NEXT)/mg, '<b>$1</b>')
        formattedText = formattedText.replace(/^(HANDOVER)/mg, '<b>$1</b>')
        formattedText = formattedText.replace(/^(END_CHAT)/mg, '<b>$1</b>')
        formattedText = formattedText.replace(/\n/g, '<br/>')

        textRef.current.innerHTML = formattedText;
        //console.log('formatText', formattedText)
         //setSavedCursorPosition()
         restoreCursorPosition()
    }

    //<textarea value={dialog} rows={40} cols={80} onChange={(e) => setDialog(e.target.value)}/>

    // create array with 10 numbers and map it to links
    const chapterArray = Array.from(Array(chapters).keys())



    return(
        <div>
            <div>
            <Link to={`/content`}>Back</Link> |&nbsp;
           {chapterArray.map((c, index) => {
                return <Link  style={{marginRight: 10}} key={index} to={`/content/${requestedDialog}/${index}`} onClick={(() => {
                    setCurrentChapter(index)
                    setLoaded(false)
                })}>Chapter {index}</Link>
           })
           }
            </div>

            <h1>{requestedDialog}/{currentChapter}</h1>


            <div style={{display: 'flex', justifyContent: 'center', gap: 60}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    { !showParams &&
                    <button style={{padding: 15}} onClick={() => setShowParams(true)}>Show Parameters</button>
                    }
                    { showParams &&
                    <>
                    <label>Session Parameter</label>
                    <textarea value={JSON.stringify(params)} rows={3} cols={80} onChange={(e) => setParams(JSON.parse(e.target.value))}/>

                    <label>Quest Parameter</label>
                    <textarea value={JSON.stringify(quest)} rows={3} cols={80} onChange={
                        (e) => {
                            try {
                                setQuest(JSON.parse(e.target.value))
                            }
                            catch {
                                setQuest(undefined)
                            }
                        }
                    }/>

                    <button onClick={() => setShowParams(false)}>Hide</button>
                    </>
                    }

                    <button style={{padding: 15}} onClick={() => formatText(dialog)}>Update Syntax Highlight</button>
                    <div
                        ref={textRef}
                        contentEditable
                        spellCheck
                        onInput={(e) => {
                            if(textRef.current) {
                                setDialog(textRef.current.innerText)
                            }
                        }}
                        style={{
                            backgroundColor: 'seashell',
                            fontFamily: 'monospace',
                            padding: 20,
                            borderColor: '#333',
                            borderRadius: 10,
                            borderWidth: 1,
                            height: 600,
                            minWidth: 600,
                            overflowY: 'scroll',
                        }}
                    />
                </div>
                <div>
                    <button onClick={restart} style={{padding: 10, borderRadius: 10, margin: 10}}>Simulate</button>
                <ChatSimulator history={history} setHistory={setHistory} dialog={dialog} setLiveParams={setLiveParams}/>

                <div>
                    <h3>Live Parameter:</h3>
                { liveParams && Object.keys(liveParams).map((key, index) => {
                    return <div key={index}>{key}: {JSON.stringify(liveParams[key])}</div>
                })}
            </div>

                </div>
            </div>

        </div>
    )
}

export default Dialog
