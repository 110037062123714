import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#ddd",
    color: "#333",
    hoverBg: "#f2f2f2",
    activeBg: "#889"
  },
  topbar: {
    bg: "#fff",
    color: "#000"
  },
  mainBg: colors.grey["100"]
};

export default colorConfigs;