import React from "react"
import BackendClient, { IAnswer } from "../../lib/BackendClient"
import ScaleOption from "./ScaleOption"

type ChatSimulatorProps = {
    dialog: string
    history: IAnswer[]
    setHistory: Function
    setLiveParams: Function
}
const height = 700

const TypingIndicator = () =>  <div key={"isTyping"} style={{
    backgroundColor: '#eee',
    color: '#000',
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    maxWidth: 300,
    alignSelf: 'flex-start',
    fontSize: 16,
}}>...</div>

export const ChatSimulator = (props: ChatSimulatorProps) => {
    const lastAnswer = props.history[props.history.length - 1]
    const [message, setMessage] = React.useState<string>('')
    const bottomEl = React.useRef<null | HTMLDivElement>(null)
    const [isTyping, setIsTyping] = React.useState<boolean>(false)

    React.useEffect(() => {
        bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [props.history])



    const submitMessage = async () => {
        if(message.trim().length < 1) return
        setIsTyping(true)

        const userMessage: IAnswer = {
            text: message,
            hasError: false,
            role: 'user'
        }
        props.setHistory((history: IAnswer[]) => [...history, userMessage])

        BackendClient.instance.simulateDialogContinue(props.dialog, message).then((result) => {
            props.setHistory((history: IAnswer[]) => [...history, result.answer])
            props.setLiveParams(result.params)
            setIsTyping(false)
        })
        setMessage('')
    }


    if(lastAnswer) {
        if(lastAnswer.text === 'END_CHAT') {
            lastAnswer.role = "system"
            lastAnswer.text = "-> Chat beendet."
        }
        else if(lastAnswer.text.startsWith('NEXT')) {
            lastAnswer.role = "system"
            lastAnswer.text = "-> Zum nächstem Thema"
        }

        if(lastAnswer.hasFollowUp && lastAnswer.role !== 'system') {
            BackendClient.instance.simulateDialogContinue(props.dialog, '').then((result) => {
                props.setHistory((history: IAnswer[]) => history.concat(result.answer))
                props.setLiveParams(result.params)
            })
        }
        //if(lastAnswer.options && lastAnswer.options.length > 0) setIsTyping(false)
    }

    return (
        <div style={{backgroundColor: 'steelblue', height: height+50, borderRadius: 30, padding: 20}}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
                width: 400,
                overflowY: 'scroll',
                height: height,
                }}>
                {props.history.map((answer: IAnswer, index: number) => {
                    if(answer.role === 'system')
                        return <div key={index} style={{fontWeight:'bold'}}>{answer.text}</div>

                    return (
                        <div key={index} style={{
                            backgroundColor: answer.role === 'assistant' ? '#eee' : '#394C7E',
                            color: answer.role === 'assistant' ? '#000' : '#fff',
                            borderRadius: 10,
                            padding: 10,
                            marginBottom: 10,
                            maxWidth: 300,
                            alignSelf: answer.role === 'assistant' ? 'flex-start' : 'flex-end',
                            fontSize: answer.feature ? 24 : 16,
                        }}>
                            <div>{answer.text}</div>
                        </div>
                    )
                })}

                { isTyping && <TypingIndicator /> }


                {lastAnswer && lastAnswer.options && // OPTIONS
                    lastAnswer.options.map((option: string, index: number) => {
                        if(option.startsWith('SCALE('))
                            return <ScaleOption
                                        key={index}
                                        config={option}
                                        setHistory={props.setHistory}
                                        setLiveParams={props.setLiveParams}
                                        dialog={props.dialog}
                            />

                        return (
                            <button key={index}
                                style={{padding: 10, borderRadius: 10, marginBottom: 10}}
                                onClick={() => {
                                props.setHistory((history: IAnswer[]) => [...history, {
                                    text: option,
                                    hasError: false,
                                    role: 'user'
                                 }])

                                BackendClient.instance.simulateDialogContinue(props.dialog, option).then((result) => {
                                    console.log(result.answer)
                                    props.setHistory((history: IAnswer[]) => [...history, result.answer])
                                    props.setLiveParams(result.params)
                                })
                            }}>{option}</button>
                        )
                    })
                }
                { !isTyping && lastAnswer && !lastAnswer.hasFollowUp && !lastAnswer.options && !lastAnswer.text.startsWith("HANDOVER") && lastAnswer.role !== 'system' &&
                    <div style={{flex: 1, flexDirection: 'row', gap: 20}}>
                        <textarea style={{
                            padding: 10,
                            borderRadius: 10,
                            marginBottom: 10,
                            width: 360,
                            height: 40,
                        }}
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value)
                            if(e.target.value.endsWith('\n')) submitMessage()
                        } }

                        />
                        { /* <button style={{marginLeft: 20, padding: 10}} onClick={submitMessage}>Send</button>*/ }
                    </div>
                }


            <div ref={bottomEl} />
            </div>

        </div>
    )
}

export default ChatSimulator