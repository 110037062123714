import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes";

import MainLayout from "./components/layout/MainLayout";
import Login from './pages/Login';

export default function App() {
  const [authenticated, setAuthenticated] = useState(false)
  if(!authenticated)
    return <>
      <Login setAuthenticated={setAuthenticated} />
    </>

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {routes}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}