import React from "react"
import BackendClient, { IUser } from "../lib/BackendClient"
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import { WithId } from "mongodb"


const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 150 },

    { field: 'pushToken', headerName: 'Push', width: 60, valueGetter: (params) => {
        return params.row.pushToken ? '📳' : ''
    }},
    { field: 'email', headerName: 'Email', width: 60, valueGetter: (params) => {
        return params.row.email ? '✅' : ''
    }},
    { field: 'usage', headerName: 'Usage', width: 150 },

    { field: 'id', headerName: 'ID', width: 300, valueGetter: (params) => {
        return `${params.row.revenueCatId}-${params.row._id}`

    }},
    { field: 'tier', headerName: 'Tier', width: 60 },
    { field: 'tierExpiration', headerName: 'Expiration', width: 350 },
]


const Users = () => {
    const [users, setUsers] = React.useState<WithId<IUser>[]>([])
    const [filtered, setFiltered] = React.useState<IUser[]>([])
    const [search, setSearch] = React.useState<string>('')

    React.useEffect(() => {
        const fetch = async () => {
            const data = await BackendClient.instance.users()
            setUsers(data)
        }

        if(users.length === 0) fetch()
    })

    React.useEffect(() => {
        if(search === '') {
            setFiltered(users)
            return
        }

        setFiltered(users.filter(user => {
            if(user.name && user.name.toLowerCase().includes(search.toLowerCase())) return true
            if(user._id.toString().toLowerCase().includes(search.toLowerCase())) return true
            return false
        }))
    }, [search, users])

    return(
        <>
            <h1>Users</h1>
            <TextField
                placeholder="Search"
                onChange={e => setSearch(e.target.value)}
            />

            <DataGrid
                rows={filtered}
                columns={columns}
                getRowId={(row) => row._id}
            />
        </>
    )
}

export default Users