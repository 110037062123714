import React from 'react'
import Slider from '@mui/material/Slider'
import BackendClient, { IAnswer } from '../../lib/BackendClient'

type ScaleOptionProps = {
    config: string
    setHistory: Function
    setLiveParams: Function
    dialog: string
}

const ScaleOption = (props: ScaleOptionProps) => {

    const [minStr, maxStr] = props.config.replace('SCALE(', '').replace(')', '').split(',')
    const min = parseInt(minStr)
    const max = parseInt(maxStr)

    const [value, setValue] = React.useState<number>(min)

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number)
    }

    return (
    <div style={{flexDirection: 'row', flex:1}}>
    <Slider aria-label="Volume" value={value} onChange={handleChange} min={min} max={max} color="secondary" />
    <button
        style={{padding: 10, borderRadius: 10, marginBottom: 10}}
        onClick={() => {
        props.setHistory((history: IAnswer[]) => [...history, {
            text: value.toString(),
            hasError: false,
            role: 'user'
        }])

        BackendClient.instance.simulateDialogContinue(props.dialog, `scale:${value}`).then((result) => {
            console.log(result.answer)
            props.setHistory((history: IAnswer[]) => [...history, result.answer])
            props.setLiveParams(result.params)
        })
    }}>{value}</button>
    </div>


    )
}

export default ScaleOption