
import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users";
import Dialog from "../pages/Dialog";
import Settings from "../pages/Settings";

// https://mui.com/material-ui/material-icons/
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Content from "../pages/Content";
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';

import { RouteType } from "./config";

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <Dashboard />,
    state: "home"
  },
  {
    path: "/",
    element: <Dashboard />,
    state: "home",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardOutlinedIcon />
    }
  },
  {
    path: "/users",
    element: <Users />,
    state: "users",
    sidebarProps: {
      displayText: "Users",
      icon: <PersonIcon />
    }
  },
  {
    path: "/content",
    element: <Content />,
    state: "content",
    sidebarProps: {
      displayText: "Content",
      icon: <ChatIcon />
    }
  },
  {
    path: "/content/:directory/:file",
    element: <Dialog />,
    state: "content",
  },
  {
    path: "/content/:directory/:file/:chapter",
    element: <Dialog />,
    state: "content",
  },
  {
    path: "/settings",
    element: <Settings />,
    state: "settings",
    sidebarProps: {
      displayText: "Settings",
      icon: <SettingsIcon />
    }
  },

]

export default appRoutes